import React from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@kyruus/drawer';
import { defineMessages, injectIntl } from 'react-intl';
import { KyruusFormattedMessage } from '@kyruus/intl';
import Cancel from '@kyruus/react-ikons/Cancel';
import { Link } from 'react-router-dom';

import {
  logClearFiltersThunk,
  getClearFiltersSearch
} from '../../shared/search-summary';

import {
  Content,
  FixedHeader,
  FixedFooter,
  Header,
  Footer,
  ClearAllFiltersButton,
  ViewResultsButton,
  DrawerRelativeWrapper
} from './styles';
import { TRACKING_EVENTS } from '../filter-bar/constants';
import Button from '@kyruus/button';

const messages = defineMessages({
  searchFilterDrawerTitle: {
    id: 'search.filter_drawer.title',
    description: 'search filter drawer title',
    defaultMessage: 'Filters'
  },
  searchFilterDrawerViewResults: {
    id: 'search.filter_drawer.view_results',
    description: 'search filter drawer view results',
    defaultMessage: 'View Results'
  },
  clearButton: {
    id: 'search.filter_drawer.clear_button',
    description: 'text for the clear button',
    defaultMessage: 'Clear all'
  }
});

/**
 * Renders a side sheet component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.dataTestId - The data test id for the component.
 * @param {string} props.anchor - The anchor position of the side sheet.
 * @param {number} props.width - The width of the side sheet.
 * @param {number} props.minWidth - The minimum width of the side sheet.
 * @param {boolean} props.isOpen - Indicates whether the side sheet is open or not.
 * @param {Function} props.closeDrawer - The function to close the side sheet.
 * @param {ReactNode} props.children - The content to be rendered inside the side sheet.
 * @returns {JSX.Element} The rendered side sheet component.
 */
function SideSheet({
  dataTestId,
  anchor,
  width,
  minWidth,
  isOpen,
  closeDrawer,
  searchSummary,
  getUpdatedSearch,
  log,
  children
}) {
  const useStyles = makeStyles({
    root: {
      '& .MuiPaper-root': { zIndex: 100 }
    }
  });
  const classes = useStyles();
  const hasSearchParamsApplied =
    searchSummary.has_filters ||
    searchSummary.filter?.length ||
    searchSummary.location;

  const handleCloseDrawer = () => {
    closeDrawer();
    log(TRACKING_EVENTS.ALL_FILTERS_CLOSE_DRAWER);
  };

  const handleClickViewResults = () => {
    closeDrawer();
    log(TRACKING_EVENTS.FILTER_PANEL_VIEW_RESULTS);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseDrawer}>
      <Drawer
        data-testid={dataTestId}
        anchor={anchor}
        open={isOpen}
        variant={'persistent'}
        className={classes.root}
      >
        <DrawerRelativeWrapper>
          <FixedHeader>
            <Header>
              <KyruusFormattedMessage {...messages.searchFilterDrawerTitle} />
              <Button
                onClick={handleCloseDrawer}
                mode="flat"
                data-testid="close-drawer-button"
              >
                <Cancel size="1.6em" aria-hidden="true" />
              </Button>
            </Header>
          </FixedHeader>

          <Content width={width} minWidth={minWidth}>
            {children}
          </Content>

          <FixedFooter>
            <Footer>
              {hasSearchParamsApplied && (
                <Link
                  to={getClearFiltersSearch(getUpdatedSearch)}
                  id="clear-filters"
                  className="clear-filters"
                  data-testid="clear-filters-button"
                  onClick={logClearFiltersThunk(log)}
                  component={ClearAllFiltersButton}
                >
                  <KyruusFormattedMessage {...messages.clearButton} />
                </Link>
              )}
              <ViewResultsButton
                data-testid="searchFilterDrawerViewResults"
                mode="primary"
                onClick={handleClickViewResults}
              >
                <KyruusFormattedMessage
                  {...messages.searchFilterDrawerViewResults}
                />
              </ViewResultsButton>
            </Footer>
          </FixedFooter>
        </DrawerRelativeWrapper>
      </Drawer>
    </ClickAwayListener>
  );
}

SideSheet.propTypes = {
  dataTestId: PropTypes.string,
  anchor: PropTypes.string.isRequired,
  width: PropTypes.string,
  isOpen: PropTypes.bool,
  closeDrawer: PropTypes.func,
  searchSummary: PropTypes.object,
  getUpdatedSearch: PropTypes.func,
  log: PropTypes.func,
  children: PropTypes.node
};

export default injectIntl(SideSheet);
