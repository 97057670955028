import React, {
  useRef,
  useState,
  KeyboardEvent,
  MouseEvent,
  ComponentPropsWithoutRef
} from 'react';
import { PMCConfig } from '@kyruus/types';

import {
  SearchFacetWithAppliedTerms,
  SearchModification,
  SearchSummary,
  Log
} from 'Src/types';
import { AppliedChip } from './AppliedChip';
import { LocationChip } from './LocationChip';
import { UnappliedChip } from './UnappliedChip';

import { getTabIndex } from '../utils';
import { FilterPopover } from '../FilterPopover';
import { TRACKING_EVENTS } from '../../constants';

export interface BaseChipProps extends ComponentPropsWithoutRef<'div'> {
  facet: SearchFacetWithAppliedTerms;
  index: number;
  log: Log;
  onClick: () => void;
}

interface FilterChipProps {
  activeChip: number;
  config: PMCConfig;
  facet: SearchFacetWithAppliedTerms;
  updateSearch: (modifications: SearchModification[]) => void;
  index: number;
  log: Log;
  onFocus: () => void;
  searchSummary: SearchSummary;
}

export const FilterChip = ({
  activeChip,
  config,
  facet,
  updateSearch,
  index,
  log,
  onFocus,
  searchSummary
}: FilterChipProps) => {
  const chipRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const selected = activeChip === index;
  const tabIndex = getTabIndex(activeChip, selected, index);

  const handleOpenPopover = (event = 'click') => {
    setOpen(true);
    log(TRACKING_EVENTS.OPEN_FILTER_MENU, {
      facet: facet.field,
      event
    });
  };

  const handleClosePopover = (
    _e: KeyboardEvent | MouseEvent,
    reason: 'escapeKeyDown' | 'backdropClick' | 'closeButton'
  ) => {
    setOpen(false);
    log(TRACKING_EVENTS.CLOSE_FILTER_MENU, {
      facet: facet.field,
      reason
    });
    // TODO: after upgrading to the biome chip, we can move the chipRef to the chip component and
    // remove the wrapping div will return focus to the chip after the popover closes.
    // After that we can remove this timeout that waits for the paper element to close before setting
    // focus back to the chip because MUI will handle that when the popover anchorEl is the chip
    const timeout = setTimeout(function () {
      const chip = chipRef.current?.firstChild;
      if (chip != null) {
        (chip as HTMLElement)?.focus();
      }
      clearTimeout(timeout);
    }, 200);
  };

  const handleChipKeyDown = (e: KeyboardEvent) => {
    // older browsers use 'Spacebar' instead of ' '
    if (['Enter', 'ArrowDown', ' ', 'Spacebar'].includes(e.key)) {
      e.preventDefault();
      handleOpenPopover(e.key);
    }
  };

  const locationChip = facet.field === 'location';
  const appliedTermsChip = !locationChip && facet.appliedTerms.length > 0;
  const unappliedChip = !locationChip && facet.appliedTerms.length === 0;
  const chipProps = {
    'aria-expanded': open,
    'aria-haspopup': true,
    keyshortcuts: 'ArrowDown',
    facet: facet,
    index: index,
    log: log,
    onFocus: onFocus,
    onKeyDown: handleChipKeyDown,
    onClick: () => handleOpenPopover('click'),
    tabIndex: tabIndex
  };

  return (
    <div ref={chipRef}>
      {locationChip && (
        <LocationChip
          searchSummary={searchSummary}
          updateSearch={updateSearch}
          {...chipProps}
        />
      )}
      {appliedTermsChip && (
        <AppliedChip updateSearch={updateSearch} {...chipProps} />
      )}
      {unappliedChip && <UnappliedChip {...chipProps} />}

      <FilterPopover
        config={config}
        facet={facet}
        updateSearch={updateSearch}
        log={log}
        onClose={handleClosePopover}
        open={open}
        searchSummary={searchSummary}
        targetEl={chipRef}
      />
    </div>
  );
};
