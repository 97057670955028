import { MutableRefObject, useEffect, useState, useMemo } from 'react';
import _debounce from 'lodash/debounce';

export const SCROLL_AMOUNT = 100;

export const useHorizontalScroll = (
  ref: MutableRefObject<HTMLDivElement | null>
) => {
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const setScrollability = useMemo(
    () =>
      _debounce(() => {
        if (ref.current == null) return;
        const toolbar = ref.current.children[1];
        const scrollLeft = toolbar.scrollLeft;
        const scrollWidth = toolbar.scrollWidth;

        const clientWidth = toolbar.clientWidth;
        const hasScrollableArea = scrollWidth > clientWidth;
        const notScrolledAllTheWayToTheRight =
          scrollWidth - clientWidth - scrollLeft > 1;

        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(hasScrollableArea && notScrolledAllTheWayToTheRight);
      }, 100),
    [ref, setCanScrollLeft, setCanScrollRight]
  );

  useEffect(() => {
    setScrollability();

    const resizeObserver = new ResizeObserver(setScrollability);

    if (ref.current != null) {
      const toolbar = ref.current.children[1];

      if (resizeObserver) {
        resizeObserver.observe(toolbar);
      }
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [setScrollability, ref]);

  const handleScroll = (scrollAmount: number) => {
    if (ref.current == null) return;

    const toolbar = ref.current.children[1];
    toolbar.scrollLeft = toolbar.scrollLeft + scrollAmount;
    setScrollability();
  };

  const scrollLeft = () => handleScroll(-SCROLL_AMOUNT);
  const scrollRight = () => handleScroll(SCROLL_AMOUNT);

  return {
    scrollLeft,
    scrollRight,
    canScrollLeft,
    canScrollRight,
    setScrollability
  };
};
