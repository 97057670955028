import React from 'react';
import { KyruusFormattedMessage } from '@kyruus/intl';
import ArrowDown3 from '@kyruus/react-ikons/ArrowDown3';

import { UnappliedFilterChip, UnappliedFilterChipLabel } from '../../styles';
import { CHIP_ROLE } from '../../constants';
import { getChipMessage } from '../utils';
import { BaseChipProps } from '.';

export const UnappliedChip = ({
  facet,
  index,
  onClick,
  log,
  ...props
}: BaseChipProps) => {
  const message = getChipMessage(facet);

  return (
    <UnappliedFilterChip
      {...props}
      label={
        <UnappliedFilterChipLabel>
          <KyruusFormattedMessage
            {...message}
            values={{
              field: message.label
            }}
          />
          <ArrowDown3 size="1.6em" aria-hidden="true" />
        </UnappliedFilterChipLabel>
      }
      onClick={onClick}
      role={CHIP_ROLE}
      data-index={index}
    />
  );
};
