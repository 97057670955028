import { defineMessages } from 'react-intl';

export default defineMessages({
  removeFilterChipLabel: {
    id: 'facet_chips.remove_filter',
    description: 'Accessibility label for removing a search facet chip',
    defaultMessage: 'Remove filter'
  },
  locationFilterChipLabel: {
    id: 'facet_chips.location',
    description: 'Summary of search location information',
    defaultMessage:
      'Within {distance, plural, =Any {any miles} one {# mile} other {# miles}} of {location}'
  },
  booleanFilterChipLabel: {
    id: 'facet_chip.applied-boolean-filter',
    description: 'Filter chip boolean label',
    defaultMessage: '{field} {termCount}'
  },
  filterChipLabel: {
    id: 'facet_chip.applied-filter',
    description: 'Filter chip label',
    defaultMessage: '{field} {termCount, plural, =0 {} other {+#}}'
  },
  filterChipAriaLabel: {
    id: 'facet_chip.applied-filter-aria-label',
    description: 'Filter chip aria label',
    defaultMessage: '{chipLabel}. To delete this filter, press delete.'
  }
});
