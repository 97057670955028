import React, { useState, ReactElement } from 'react';
import _clone from 'lodash/clone';
import _kebabCase from 'lodash/kebabCase';
import _map from 'lodash/map';
import _remove from 'lodash/remove';
import _slice from 'lodash/slice';
// eslint-disable-next-line no-restricted-imports
import { FormattedMessage } from 'react-intl';

import {
  SearchModification,
  FilterParam,
  SearchFacetWithAppliedTerms
} from 'Src/types';
import { BasicContentFilter } from './BasicContentFilter';
import { ShowMoreButton as JSShowMoreButton } from '../../../facet-panel/facet-list/basic-facet/show-more-button';
import { BasicFacetBody, FilterWrapper } from '../../../styles';

// TODO: remove this typecast once we have fully migrated to react 17
const ShowMoreButton = JSShowMoreButton as any as (props: any) => ReactElement;
interface BasicContentProps {
  facet: SearchFacetWithAppliedTerms;
  labelName: Pick<
    FormattedMessage.Props,
    'id' | 'description' | 'defaultMessage'
  >;
  hideCount: boolean;
  updateSearch: (modifications: SearchModification[]) => void;
}

export const BasicContent = ({
  facet,
  labelName,
  hideCount,
  updateSearch
}: BasicContentProps) => {
  const [hideTerms, setHideTerms] = useState(true);

  const toggleShowMore = () => {
    setHideTerms(!hideTerms);
  };

  const handleAddFilter = (filterParam: FilterParam) => {
    setHideTerms(true);
    updateSearch([{ action: 'append', key: 'filter', value: filterParam }]);
  };
  const handleRemoveFilter = (filterParam: FilterParam) => {
    setHideTerms(true);
    updateSearch([
      { action: 'delete_key_value', key: 'filter', value: filterParam }
    ]);
  };
  const facetName = facet.field;
  const maxVisible = facet.max_visible || 5;
  const terms = _clone(facet.terms);
  const appliedTerms = _remove(terms, 'applied');

  const appliedFilters = _map(appliedTerms, (term) => (
    <BasicContentFilter
      facetType={facet.type}
      term={term}
      facetName={facetName}
      hideCount={hideCount}
      toggleFilter={handleRemoveFilter}
      key={term.value}
    />
  ));

  const to_show = hideTerms
    ? Math.max(maxVisible - appliedTerms.length, 0)
    : terms.length;
  const unappliedTerms = _slice(terms, 0, to_show);
  const unappliedFilters = _map(unappliedTerms, (term) => (
    <BasicContentFilter
      facetType={facet.type}
      term={term}
      facetName={facetName}
      hideCount={hideCount}
      toggleFilter={handleAddFilter}
      key={term.value}
    />
  ));

  return (
    <FilterWrapper className="filter-box multi">
      <BasicFacetBody
        data-testid={`filter-box-${_kebabCase(facetName)}`}
        className="no-bullets"
        id={'facet-group-' + _kebabCase(facetName)}
      >
        {appliedFilters}
        {unappliedFilters}
      </BasicFacetBody>
      <ShowMoreButton
        allTerms={facet.terms}
        facetName={facetName}
        hideTerms={hideTerms}
        labelName={labelName}
        maxVisible={maxVisible}
        onClick={toggleShowMore}
        terms={terms}
      />
    </FilterWrapper>
  );
};
