import React, { ReactElement } from 'react';
import _ from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import {
  SearchFacetWithAppliedTerms,
  SearchModification,
  Term
} from 'Src/types';
import { IntlReactSelect as ExistingIntlReactSelect } from '../../../../utils/intl-components';
import { FilterWrapper, TypeaheadSelectBody } from '../../../styles';

type MessageDescriptor = Pick<
  FormattedMessage.Props,
  'id' | 'description' | 'defaultMessage'
>;

interface IntlOption extends Pick<Term, 'count' | 'value' | 'top_filter'> {
  labelMessageDescriptor: MessageDescriptor;
}

type IntlOptionMessage = {
  labelMessageDescriptor?: MessageDescriptor;
  options: IntlOption[];
};
type ChangedItems = { value: string }[];

type RenderedOption = { value: string; label: string; count: number };

interface IntlReactSelectProps {
  value: string[];
  instanceId: string;
  multi: boolean;
  clearable: boolean;
  placeholderMessageDescriptor: FormattedMessage.Props;
  defaultPlaceholderMessageDescriptor: MessageDescriptor;
  optionsMessageDescriptors: IntlOptionMessage[];
  ariaMessageDescriptor: MessageDescriptor;
  onChange: (changed: ChangedItems) => void;
  optionRenderer: (item: RenderedOption) => ReactElement;
}
const IntlReactSelect = ExistingIntlReactSelect as any as (
  props: IntlReactSelectProps
) => ReactElement;

interface TypeaheadContentProps {
  facet: SearchFacetWithAppliedTerms;
  hideCount: boolean;
  updateSearch: (modifications: SearchModification[]) => void;
}

export const TypeaheadContent = ({
  facet,
  hideCount,
  updateSearch
}: TypeaheadContentProps) => {
  const facetName = facet.field;
  // We want the default text here to be sent to translators, but since the ID can't
  // be determined statically, we can't send it through the defineMessages API.
  const placeholderMessageDescriptor = {
    id: `facet.${facet.field}.typeahead.placeholder`,
    defaultMessage: ''
  };

  const facetTerms = _.map(facet.terms, function (filter) {
    const labelMessageDescriptor = {
      id: `field.value.${facetName}.${filter.value}`,
      defaultMessage: filter.value,
      description: 'The label for the filter'
    };
    return {
      labelMessageDescriptor: labelMessageDescriptor,
      value: filter.filter_param,
      applied: filter.applied,
      top_filter: filter.top_filter,
      count: filter.count
    };
  });
  const applied = _.chain(facetTerms).filter('applied').map('value').value();
  const top_filters: IntlOption[] = _.chain(facetTerms)
    .remove('top_filter')
    .map((filter) =>
      _.pick(filter, ['labelMessageDescriptor', 'value', 'count'])
    )
    .value();
  const facetTypeaheadTerms = _.map(facetTerms, (filter) =>
    _.pick(filter, ['labelMessageDescriptor', 'value', 'count'])
  );

  let optionsMessageDescriptors: IntlOptionMessage[];
  if (top_filters.length) {
    optionsMessageDescriptors = [
      {
        labelMessageDescriptor: messages.topfilter,
        options: top_filters
      },
      {
        labelMessageDescriptor: messages.allfilter,
        options: facetTypeaheadTerms
      }
    ];
  } else {
    optionsMessageDescriptors = [
      {
        options: facetTypeaheadTerms
      }
    ];
  }

  const onChange = (changed: ChangedItems) => {
    const changedValues = _.map(changed, 'value');
    if (changedValues.length > applied.length) {
      const toAdd = _.difference(changedValues, applied);
      const modifications: SearchModification[] = [
        { action: 'append', key: 'filter', value: toAdd[0] }
      ];
      updateSearch(modifications);
    } else if (changedValues.length < applied.length) {
      const toRemove = _.difference(applied, changedValues);
      const modifications: SearchModification[] = [
        { action: 'delete_key_value', key: 'filter', value: toRemove[0] }
      ];
      updateSearch(modifications);
    }
  };
  return (
    <FilterWrapper
      id={_.kebabCase(facetName)}
      data-testid={`select-area-${_.kebabCase(facetName)}`}
    >
      <TypeaheadSelectBody>
        <IntlReactSelect
          instanceId={_.kebabCase(facetName)}
          multi={true}
          clearable={false}
          value={applied}
          placeholderMessageDescriptor={placeholderMessageDescriptor}
          defaultPlaceholderMessageDescriptor={messages.defaultplaceholder}
          optionsMessageDescriptors={optionsMessageDescriptors}
          ariaMessageDescriptor={messages.arialabel}
          onChange={onChange}
          optionRenderer={(item) => {
            return (
              <>
                {item.label}
                {!hideCount && item.count ? (
                  <span className="count"> ({item.count})</span>
                ) : null}
              </>
            );
          }}
        />
      </TypeaheadSelectBody>
    </FilterWrapper>
  );
};
