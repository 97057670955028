import React, { ReactElement, ComponentPropsWithoutRef } from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { kyruusFormatHTMLMessage } from '@kyruus/intl';

import { DISTANCE_ANY } from '../../../facet-panel/facet-list/location-facet';

import { SearchModification, SearchSummary } from 'Src/types';
import { RemoveFilterChipIcon, FilterChip } from '../../styles';
import { CHIP_ROLE } from '../../constants';
import messages from '../messages';
import { BaseChipProps } from '.';

interface BaseLocationChipProps
  extends BaseChipProps,
    ComponentPropsWithoutRef<'div'> {
  updateSearch: (modifications: SearchModification[]) => void;
  searchSummary: SearchSummary;
  onClick: () => void;
}

type LocationChipProps = InjectedIntlProps & BaseLocationChipProps;

export const LocationChip = injectIntl(
  ({
    intl,
    searchSummary,
    onClick,
    updateSearch,
    log,
    ...props
  }: LocationChipProps) => {
    const handleDelete = () => {
      updateSearch([
        { action: 'delete_key', key: 'display_location' },
        { action: 'delete_key', key: 'location' },
        { action: 'delete_key', key: 'distance' }
      ]);
    };

    const chipLabel = kyruusFormatHTMLMessage(
      intl,
      messages.locationFilterChipLabel,
      {
        location: searchSummary.display_location ?? searchSummary.location,
        distance: searchSummary.distance || DISTANCE_ANY
      }
    );

    return (
      <FilterChip
        {...props}
        aria-label={kyruusFormatHTMLMessage(
          intl,
          messages.filterChipAriaLabel,
          { chipLabel }
        )}
        color="primary"
        role={CHIP_ROLE}
        data-testid="filter-chip-location"
        label={chipLabel}
        onClick={onClick}
        onDelete={handleDelete}
        deleteIcon={
          <RemoveFilterChipIcon
            size="1.6em"
            aria-hidden="true"
            title={kyruusFormatHTMLMessage(
              intl,
              messages.removeFilterChipLabel
            )}
          />
        }
      />
    );
  }
  // TODO: remove this typecast once we have fully migrated to react 17
) as any as (props: BaseLocationChipProps) => ReactElement;
