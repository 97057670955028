import Banner from '@kyruus/banner';
import { KyruusFormattedMessage, kyruusFormatMessage } from '@kyruus/intl';
import Map from '@kyruus/react-ikons/Map';
import _isEmpty from 'lodash/isEmpty';
import _isInteger from 'lodash/isInteger';
import _map from 'lodash/map';
import querystring from 'querystring';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { FilterBar } from './filter-bar';
import ErrorMessage from '../error/error';
import AvailabilityControls from '../shared/AvailabilityControls';
import LoadingOverlay from '../shared/loading';
import ProviderListV9, { FROM_SEARCH_LIST } from '../shared/provider-list-v9';
import { events, pages, placements } from '../tracking/constants';
import { getLogWithProvider } from '../tracking/decorators';
import { getVisibility } from '../utils/search-common';
import { FacetChips } from './facet-chips';
import { FacetPanel } from './facet-panel';
import MapSearchLinkBlock from './map-search-link-block';
import { MAP_BG_URL } from './map-search-link-block/constants';
import NoResultsContent from './no-results';
import ResultsHeader, {
  EmergencyBanner,
  EmergencyBannerMd,
  MobileResultsHeader
} from './results-header';
import SearchFooter from './search-footer';
import SearchMetas from './search-metas';

import {
  MODULES,
  isModuleEnabled,
  shouldRenderDirectBookInDrawer
} from 'Common/config';
import { mapSearchCallbackFunction } from '../map-search/lib/utils';
import {
  getProviderDisplayName,
  getProviderPhoneNumber
} from '../provider/utils';
import { V9SearchAlerts } from '../shared/search-alerts/search-alerts';
import { scrollToTopCCF } from '../utils/cerner';
import {
  V9_HAS_AVAILABILITY_PARAM_KEY,
  V9_PATIENT_REL_FILTER_KEY,
  V9_PURPOSE_FILTER_KEY
} from '../utils/constants';
import { getMapSearchURL, mapSearchModuleEnabled } from '../utils/map-search';
import AvailabilityModal from './availability-modal';
import { DISTANCE_ANY } from './facet-panel/facet-list/location-facet';
import {
  AvailabilityControlsContainer,
  ExpandedFilterDrawerOverlay
} from './styles';
import {
  getParamsForConfirmAvailability,
  getPurposeOptionsByPatientRel,
  hidePatientRel,
  showSearchFooterDisclaimer,
  splitObjectKeys
} from './utils';

import { getApptPurposeFilterProps } from '../shared/appt-purpose-filtering';

import SideDrawerFilterPanel from './SideDrawerFilterPanel';

const messages = defineMessages({
  providerListHeader: {
    id: 'providerlist.header',
    description: 'Header for a list of search results ',
    defaultMessage: 'Search results'
  },
  providerListExpandedSearchRadiusFullWarning: {
    id: 'providerlist.expanded_search_radius_full_warning',
    description:
      'Warning message that the distance filter was removed when no results are found for a provided search radius and even within a 100 mile radius',
    defaultMessage: `No providers found near the selected location, showing all results for "{searchDescription}"`
  },
  providerListExpandedSearchRadiusPartialWarning: {
    id: 'providerlist.expanded_search_radius_partial_warning',
    description:
      'Warning message when no results are found and the search radius is automatically expanded to get results',
    defaultMessage: `No providers found within {
        originalDistance,
        plural,
        one {# mile}
        other {# miles}
      }, showing providers within {
        expandedDistance,
        plural,
        one {# mile}
        other {# miles}
        =Any {any miles}
      }`
  },
  bannerCloseAriaLabel1: {
    id: 'banner.close.arialLabel1',
    description:
      'action1 close icon aria label text to be read by screen reader',
    defaultMessage: 'close'
  }
});

class Search extends React.Component {
  constructor() {
    super();
    this.state = {
      toggle: false,
      isAvailabilityModalOpen: false,
      availableAppt: null,
      patientRel: '',
      purpose: null,
      purposeHasNoAvailability: null,
      patientRelHasNoAvailability: null,
      shouldHidePatientRel: false,
      showFilterDrawer: false
    };

    this.handleOnChangeSwitch = this.handleOnChangeSwitch.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleConfirmModal = this.handleConfirmModal.bind(this);
    this.onPatientRelSelect = this.onPatientRelSelect.bind(this);
    this.onSelectPurpose = this.onSelectPurpose.bind(this);
    this.onClickShowAvailability = this.onClickShowAvailability.bind(this);
    this.onAvailabilityTilesLoaded = this.onAvailabilityTilesLoaded.bind(this);
    this.handleShowFilterDrawer = this.handleShowFilterDrawer.bind(this);
    this.handleHideFilterDrawer = this.handleHideFilterDrawer.bind(this);
  }

  componentDidMount() {
    const {
      location,
      setPatientRel,
      setPurpose,
      fetchGlobalPurposesByPatientRelV9,
      log,
      slotsByProviderId,
      fetchSlots,
      history
    } = this.props;

    if (slotsByProviderId) {
      // refresh slots
      Object.entries(slotsByProviderId).forEach(([key, { apptInfo }]) => {
        fetchSlots(apptInfo, [key]);
      });
    }

    const query = querystring.parse(location.search.substring(1));
    const querystringFilters =
      query.filter &&
      Array.isArray(query.filter) &&
      query.filter.length &&
      Object.assign(...splitObjectKeys(query.filter));

    const querystringPurpose =
      querystringFilters && querystringFilters[V9_PURPOSE_FILTER_KEY];

    if (querystringPurpose) {
      const querystringPatientRel =
        querystringFilters[V9_PATIENT_REL_FILTER_KEY];

      this.setState({
        purpose: {
          name: querystringPurpose
        },
        patientRel: querystringPatientRel,
        availableAppt: {
          patientRel: querystringPatientRel,
          purpose: querystringPurpose
        },
        toggle: true
      });

      setPatientRel(querystringPatientRel);
      setPurpose(querystringPurpose);
      querystringPatientRel &&
        fetchGlobalPurposesByPatientRelV9(querystringPatientRel, log);
    }

    if (query.relationship && query.purpose) {
      // remove those params to avoid cross-contamination with other providers
      // after returning to search from a provider profile with availability params attached
      const copyOfQuery = {
        ...query
      };

      delete copyOfQuery.relationship;
      delete copyOfQuery.purpose;

      history.push(`/search?${querystring.stringify(copyOfQuery)}`);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      searchSummary,
      location,
      searchContext,
      apptInfo,
      log,
      fetchGlobalPurposesByPatientRelV9
    } = this.props;

    const query = querystring.parse(location.search.substring(1));

    const locationChanged = prevProps.location.search !== location.search;
    const searchTermChanged =
      prevProps?.searchSummary?.search_query &&
      prevProps.searchSummary.search_query !== searchSummary.search_query;

    // reset state if the search term or the url changes
    if (searchTermChanged || locationChanged) {
      let newState = { providerId: null };

      const filtersReset = locationChanged && query.filter == null;
      // reset everything if the search term changed
      if (searchTermChanged || filtersReset) {
        this.props.resetAvailability();
        newState = {
          ...newState,
          availableAppt: null,
          patientRel: '',
          purpose: null,
          toggle: false
        };
      }

      this.setState(newState);
    }

    if (searchContext !== prevProps.searchContext && apptInfo.relationship) {
      fetchGlobalPurposesByPatientRelV9(apptInfo.relationship, log);
    }
  }

  reset() {
    const { patientRel, purpose } = this.state;
    const { history, getUpdatedSearch, config } = this.props;
    const { v9DirectBookEnabledFilter } = config.visibility;

    this.props.resetAvailability();
    this.setState({
      availableAppt: null,
      patientRel: '',
      purpose: null
    });

    const filter = [
      `${V9_PATIENT_REL_FILTER_KEY}:${patientRel}`,
      `${V9_PURPOSE_FILTER_KEY}:${purpose.name}`,
      v9DirectBookEnabledFilter
    ];

    history.push(
      getUpdatedSearch([
        { action: 'delete_key_value', key: 'filter', value: filter },
        {
          action: 'delete_key',
          key: V9_HAS_AVAILABILITY_PARAM_KEY,
          value: true
        }
      ])
    );
  }

  handleOnChangeSwitch() {
    const { log } = this.props;
    this.setState((prev) => ({ toggle: !prev.toggle }));
    if (this.state.providerId) {
      this.setState(
        {
          isAvailabilityModalOpen: true,
          patientRel: '',
          providerId: null
        },
        () => {
          this.onPatientRelSelect('new');
        }
      );
    }
    if (this.state.availableAppt) {
      log('user_action.show.provider_availability_toggle_off');
      this.reset();
    } else {
      log('user_action.show.provider_availability_toggle_on');
      this.setState({
        isAvailabilityModalOpen: true,
        patientRel: 'new',
        providerId: null
      });
      this.onPatientRelSelect('new');
    }

    // never hide patient rel for global modal
    this.setState({
      shouldHidePatientRel: false
    });

    scrollToTopCCF();
  }

  handleEditClick() {
    const { log } = this.props;
    log('user_action.show.provider_availability_toggle_edit');
    this.setState({
      isAvailabilityModalOpen: true
    });
    scrollToTopCCF();
  }

  handleCloseModal(type) {
    const { log } = this.props;
    log(`user_action.show_available_appt_modal.${type}`);
    const { availableAppt } = this.state;
    const isAvailableAppt = !!availableAppt;

    let patientRel, purpose;

    if (isAvailableAppt) {
      patientRel = availableAppt.patientRel;
      purpose = { name: availableAppt.purpose };
    } else {
      patientRel = '';
      purpose = null;
    }

    this.setState({
      isAvailabilityModalOpen: false,
      patientRel,
      purpose,
      toggle: isAvailableAppt,
      providerId: null,
      purposeHasNoAvailability: null,
      patientRelHasNoAvailability: null
    });
  }

  handleConfirmModal() {
    const { purpose, patientRel, providerId } = this.state;
    const {
      setPurpose,
      setPatientRel,
      getUpdatedSearch,
      history,
      fetchSlots,
      config
    } = this.props;

    const availableAppt = providerId
      ? null
      : {
          patientRel: patientRel,
          purpose: purpose.name
        };

    if (availableAppt) {
      this.setState({
        isAvailabilityModalOpen: false,
        availableAppt,
        providerId: null,
        purpose: providerId ? null : purpose,
        patientRel: providerId ? null : patientRel
      });
      const params = getParamsForConfirmAvailability({
        config,
        purpose,
        patientRel
      });
      history.push(getUpdatedSearch(params));
    } else {
      this.setState({
        isAvailabilityModalOpen: false,
        providerId
      });
    }
    setPatientRel(patientRel);
    setPurpose(purpose.name);
    if (providerId) {
      fetchSlots(
        {
          relationship: patientRel,
          purpose: purpose.name
        },
        [providerId]
      );
    }
  }

  onPatientRelSelect(patientRel) {
    const { apptInfo, fetchGlobalPurposesByPatientRelV9, log } = this.props;
    const { providerId, availableAppt } = this.state;
    log('user_action.provider_availability_type_selected', {
      patient_rel: patientRel
    });
    this.setState(
      (state) => {
        const newStateFragment = {
          patientRel,
          purpose: null,
          purposeHasNoAvailability: false
        };

        if (
          state.providerId != null &&
          this.props.availablePurposesByProviderId != null &&
          this.props.availablePurposesByProviderId[this.state.providerId] !=
            null
        ) {
          const availablePurposes =
            this.props.availablePurposesByProviderId[this.state.providerId];

          if (availablePurposes) {
            newStateFragment.patientRelHasNoAvailability =
              !availablePurposes[patientRel].size;
          }
        }
        return newStateFragment;
      },
      () =>
        (!providerId || availableAppt) &&
        !apptInfo.purposeOptions[patientRel].length &&
        fetchGlobalPurposesByPatientRelV9(patientRel, log)
    );
  }

  onSelectPurpose(purpose) {
    this.props.log('user_action.appt_purpose_type_selected', {
      appt_purpose: purpose
    });

    if (this.state.providerId) {
      const availablePurposes =
        this.props.availablePurposesByProviderId[this.state.providerId];

      this.setState({
        purpose,
        purposeHasNoAvailability: !availablePurposes[this.state.patientRel].has(
          purpose.name
        )
      });
    }

    this.setState({ purpose });
  }

  onClickShowAvailability(provider) {
    const { config, log, fetchPurposesByProviderId } = this.props;
    log('user_action.show.provider_availability_tile', {
      provider_id: provider.id
    });
    const shouldHidePatientRel = hidePatientRel(
      provider,
      config.direct_book.hide_patient_rel_for_locations
    );
    const defaultPatientRel = shouldHidePatientRel ? 'established' : '';
    if (this.state.providerId) {
      this.setState({
        isAvailabilityModalOpen: true,
        providerId: provider.id,
        patientRel: defaultPatientRel,
        purpose: null,
        shouldHidePatientRel: shouldHidePatientRel
      });
    } else {
      this.setState({
        isAvailabilityModalOpen: true,
        providerId: provider.id,
        patientRel: defaultPatientRel,
        shouldHidePatientRel: shouldHidePatientRel
      });
    }

    fetchPurposesByProviderId(provider.id, log);
    scrollToTopCCF();
  }

  shouldOpenDirectBookSameWindow() {
    const { config } = this.props;
    return isModuleEnabled(config, MODULES.DIRECT_BOOK);
  }

  onAvailabilityTilesLoaded(availabilitiesCount, provider_id) {
    const { providerId, log } = this.props;
    if (this.state.providerId === providerId) {
      log('user_action.show_show_available_appt_times', {
        provider_id,
        count: availabilitiesCount.length
      });
    }
  }

  handleShowFilterDrawer() {
    this.setState({ showFilterDrawer: true });
  }

  handleHideFilterDrawer() {
    this.setState({ showFilterDrawer: false });
  }

  getPhoneNumberForSelectedProvider(selectedProviderId, providers) {
    if (selectedProviderId) {
      const selectedProvider = providers.find(
        (provider) => selectedProviderId === provider.id
      );

      if (selectedProvider) {
        return getProviderPhoneNumber(selectedProvider);
      }
    }

    return null;
  }

  getSelectedProvider() {
    return this.props.providers.find((provider) => {
      return this.state.providerId === provider.id;
    });
  }

  getSlotsVisibility() {
    const { schedulingVisibility } = getVisibility(this.props.config);
    return schedulingVisibility;
  }

  render() {
    const {
      searchSummary,
      config,
      facets,
      getUpdatedSearch,
      providers,
      totalProviders,
      totalPages,
      suggestions,
      topSpecialties,
      log,
      loading,
      slotsCountLoading,
      error,
      showMobileFacets,
      toggleMobileFacets,
      distanceSort,
      sortOptions,
      apptInfo,
      availabilityButtonsConfig,
      searchProviderIds,
      slotsByProviderId,
      slotsStatusByProviderId = {},
      location,
      history,
      tokens,
      customerCode,
      productName,
      intl,
      alerts,
      match
    } = this.props;

    const { updated_filter_bar: showFiltersInDrawer } =
      config.darkship_feature_flags;

    const showLoadingOverlay = loading || slotsCountLoading;

    const handleTimeSlotsClick = (date, time, provider_id, href) => {
      const provider = providers.find((p) => p.id === provider_id);

      getLogWithProvider(log, provider)(
        'user_action.provider_timeslot_selected_search',
        {
          date,
          time
        }
      );
      if (shouldRenderDirectBookInDrawer(config) && provider) {
        history.push(href, {
          provider: {
            location,
            provider
          }
        });
      }
    };

    const handleViewMoreClick = (provider_id, href) => {
      const provider = providers.find((p) => p.id === provider_id);
      getLogWithProvider(
        log,
        provider
      )(
        `${events.USER_ACTION}.${pages.SEARCH_RESULTS}.${placements.PROVIDER_CARD}.availability_tiles.view_all_appointments`
      );
      if (shouldRenderDirectBookInDrawer(config) && provider) {
        history.push(href, {
          provider: {
            location,
            provider
          }
        });
      }
    };

    const shouldDisplayAvailability = config.display_availability_in_search;

    if (error) {
      return (
        <div>
          <LoadingOverlay loading={showLoadingOverlay} />
          <ErrorMessage />
        </div>
      );
    }

    let providerContent;

    const {
      availableAppt,
      patientRel,
      isAvailabilityModalOpen,
      purpose,
      shouldHidePatientRel
    } = this.state;

    const bookUrlTarget = this.shouldOpenDirectBookSameWindow()
      ? '_self'
      : undefined;

    if (totalProviders == 0) {
      if (!_isEmpty(suggestions) && !loading) {
        log('user_action.search_results.spell_suggestion_available', {
          suggestions: _map(suggestions, 'display_text').toString(),
          search_query: searchSummary.search_query
        });
      }
      providerContent = (
        <NoResultsContent
          suggestions={suggestions}
          topSpecialties={topSpecialties}
          getUpdatedSearch={getUpdatedSearch}
          searchQuery={searchSummary.search_query}
          log={log}
          history={history}
          config={config}
        />
      );
    } else {
      providerContent = (
        <ProviderListV9
          providers={providers}
          customerCode={customerCode}
          productName={productName}
          config={config}
          searchSummary={searchSummary}
          log={log}
          slots={slotsByProviderId}
          slotsStatus={slotsStatusByProviderId}
          onClickShowAvailability={this.onClickShowAvailability}
          availabilityLinkTarget={bookUrlTarget}
          onTimeSlotsClick={handleTimeSlotsClick}
          onViewMoreClick={handleViewMoreClick}
          onAvailabilityTilesLoaded={this.onAvailabilityTilesLoaded}
          profileSummaryParams={{
            from: FROM_SEARCH_LIST
          }}
          tokens={tokens}
        />
      );
    }

    const showExpandedSearchRadiusBanner = (searchSummary) => {
      const { distance, _original_input_distance } = searchSummary;
      if (
        typeof distance === 'undefined' ||
        typeof _original_input_distance === 'undefined'
      ) {
        return false;
      }
      return distance != _original_input_distance;
    };

    const isFullyExpandedSearchRadius = searchSummary.distance === DISTANCE_ANY;

    const showDisclaimer = showSearchFooterDisclaimer(providers);

    const showEmergencyMd =
      config.emergency_disclaimer_md && config.emergency_disclaimer_md.enabled;

    const shouldDisplayReadOnlyAvailability =
      providers && config.enable_read_only_availability;

    const bookOnlineUrlExists = (providers || []).some((provider) =>
      Boolean(provider.book_online_url)
    );

    const isGlobalApptInfoSelected =
      apptInfo?.relationship && apptInfo?.purpose;

    const showGlobalAvailabilityControls =
      bookOnlineUrlExists ||
      shouldDisplayReadOnlyAvailability ||
      // prevent dead end when global purpose is selected and no providers are returned
      (isGlobalApptInfoSelected && !searchProviderIds.length);

    const isSpecialtyPage = location.pathname.includes('/specialty');
    const isSpecialtyLocationPage =
      isSpecialtyPage && location.pathname.includes('/near');
    const additionalParams = (() => {
      if (isSpecialtyLocationPage) {
        return {
          specialties: searchSummary.specialties,
          location: searchSummary.location,
          distance: searchSummary.distance
        };
      }

      if (isSpecialtyPage) {
        return {
          specialties: searchSummary.specialties
        };
      }

      return {};
    })();
    const mapSearchUrl = getMapSearchURL(
      searchSummary.query_string,
      additionalParams
    );

    const primaryMlocFacet =
      facets &&
      facets.find(
        (f) => f.field === 'locations.primary_marketable_location_id'
      );

    const searchFacetChips = config?.search_results_page_v9?.search_facet_chips;

    const shouldShowMapLink =
      totalProviders > 0 &&
      mapSearchModuleEnabled(config) &&
      primaryMlocFacet &&
      primaryMlocFacet.total > 0;

    return (
      <main className="search-container mt-m">
        {this.state.showFilterDrawer && (
          <ExpandedFilterDrawerOverlay onClick={this.handleHideFilterDrawer} />
        )}
        <div>
          <SearchMetas
            config={config}
            match={match}
            searchSummary={searchSummary}
          />
          {showEmergencyMd ? (
            <EmergencyBannerMd
              bannerConfig={config.emergency_disclaimer_md}
              action1AriaLabel={kyruusFormatMessage(
                intl,
                messages.bannerCloseAriaLabel1
              )}
              log={log}
            />
          ) : (
            <EmergencyBanner
              config={config}
              action1AriaLabel={kyruusFormatMessage(
                intl,
                messages.bannerCloseAriaLabel1
              )}
              log={log}
            />
          )}
          <LoadingOverlay
            loading={showLoadingOverlay}
            filterDrawerOpen={this.state.showFilterDrawer}
          />
          {
            // mobile map search link block
            //desktop is inside providermatch_consumer/static/src/search-v9/facet-panel.jsx for old UI and inside FilterBar for new UI
            shouldShowMapLink && !showFiltersInDrawer && (
              <MapSearchLinkBlock
                backgroundImgUrl={MAP_BG_URL}
                linkUrl={mapSearchUrl}
                forMobile={true}
                onClick={(e) => {
                  mapSearchCallbackFunction(e, log, mapSearchUrl, history);
                }}
              >
                <Map size="60px" />
              </MapSearchLinkBlock>
            )
          }
        </div>
        <section className="row mt-m">
          {/* Temporary check to show/hide filters inside drawer */}
          {showFiltersInDrawer ? (
            <SideDrawerFilterPanel
              showMobileFacets={showMobileFacets}
              searchSummary={searchSummary}
              sortOptions={sortOptions}
              totalProviders={totalProviders}
              log={log}
              getUpdatedSearch={getUpdatedSearch}
              toggleMobileFacets={toggleMobileFacets}
              facets={facets}
              distanceSort={distanceSort}
              showLocationFacet={true}
              config={config}
              history={history}
              tokens={tokens}
              mapSearchUrl={mapSearchUrl}
              isOpen={this.state.showFilterDrawer}
              closeDrawer={this.handleHideFilterDrawer}
              loading={showLoadingOverlay}
              showFiltersInDrawer={showFiltersInDrawer} // Temporary prop to show/hide filters inside drawer
            />
          ) : (
            <aside className="col-md-3">
              <FacetPanel
                showMobileFacets={showMobileFacets}
                searchSummary={searchSummary}
                sortOptions={sortOptions}
                totalProviders={totalProviders}
                log={log}
                getUpdatedSearch={getUpdatedSearch}
                toggleMobileFacets={toggleMobileFacets}
                facets={facets}
                distanceSort={distanceSort}
                showLocationFacet={true}
                config={config}
                history={history}
                tokens={tokens}
                mapSearchUrl={mapSearchUrl}
                showFiltersInDrawer={showFiltersInDrawer} // Temporary prop to show/hide filters inside drawer
              />
            </aside>
          )}
          <section
            className={`${
              showFiltersInDrawer ? 'col-md-12' : 'col-md-9'
            } results`}
            aria-busy={loading ? 'true' : 'false'}
          >
            {showFiltersInDrawer && (
              <FilterBar
                facets={facets}
                getUpdatedSearch={getUpdatedSearch}
                searchSummary={searchSummary}
                config={config}
                onAllFiltersClick={this.handleShowFilterDrawer}
                mapSearchUrl={mapSearchUrl}
                log={log}
                onMapButtonClick={(e) => {
                  mapSearchCallbackFunction(e, log, mapSearchUrl, history);
                }}
              />
            )}
            <h1 className="sr-only">
              <KyruusFormattedMessage {...messages.providerListHeader} />
            </h1>
            <MobileResultsHeader
              totalProviders={totalProviders}
              toggleMobileFacets={toggleMobileFacets}
              config={config}
            />
            <V9SearchAlerts config={config} searchAlerts={alerts} />
            {_isInteger(totalProviders) && (
              <ResultsHeader
                totalProviders={totalProviders}
                searchSummary={searchSummary}
                sortOptions={sortOptions}
                log={log}
                getUpdatedSearch={getUpdatedSearch}
                isLoadingOverlayShown={showLoadingOverlay}
              />
            )}
            {showExpandedSearchRadiusBanner(searchSummary) && !loading && (
              <Banner
                data-testid="ExpandedSearchRadiusBanner"
                className="mt-m mb-m"
                type="warning"
                icon="!"
              >
                <KyruusFormattedMessage
                  {...(isFullyExpandedSearchRadius &&
                  searchSummary.search_description != null
                    ? messages.providerListExpandedSearchRadiusFullWarning
                    : messages.providerListExpandedSearchRadiusPartialWarning)}
                  values={{
                    expandedDistance: searchSummary.distance,
                    originalDistance: searchSummary._original_input_distance,
                    searchDescription: searchSummary.search_description
                  }}
                >
                  {(text) => <span role="status">{text}</span>}
                </KyruusFormattedMessage>
              </Banner>
            )}

            {shouldDisplayAvailability &&
              !this.props.hasFetchingSlotCountsFailed &&
              (!loading || searchSummary.search_query) &&
              showGlobalAvailabilityControls && (
                <AvailabilityControlsContainer>
                  <AvailabilityControls
                    handleEdit={this.handleEditClick}
                    handleToggle={this.handleOnChangeSwitch}
                    selectedAppointment={availableAppt}
                  />
                </AvailabilityControlsContainer>
              )}
            {searchFacetChips && !showFiltersInDrawer && (
              <FacetChips
                facets={facets}
                getUpdatedSearch={getUpdatedSearch}
                searchSummary={searchSummary}
              />
            )}
            <AvailabilityModal
              patientRel={
                (availableAppt && !patientRel && availableAppt.patientRel) ||
                patientRel
              }
              purpose={
                (availableAppt &&
                  !patientRel &&
                  !purpose && { name: availableAppt.purpose }) ||
                purpose
              }
              isOpen={isAvailabilityModalOpen}
              onPatientRelSelect={this.onPatientRelSelect}
              onSelectPurpose={this.onSelectPurpose}
              handleCloseModal={this.handleCloseModal}
              handleConfirmModal={this.handleConfirmModal}
              patientRelOptions={
                this.state.providerId
                  ? getPurposeOptionsByPatientRel(
                      this.getSelectedProvider(),
                      this.getSlotsVisibility()
                    )
                  : apptInfo.purposeOptions
              }
              providerDisplayName={
                this.state.providerId &&
                getProviderDisplayName(this.getSelectedProvider())
              }
              providerPhone={this.getPhoneNumberForSelectedProvider(
                this.state.providerId,
                providers
              )}
              availabilityButtonsConfig={availabilityButtonsConfig}
              isPurposeLoading={apptInfo.loading}
              log={log}
              patientRelHasNoAvailability={
                this.state.patientRelHasNoAvailability
              }
              purposeHasNoAvailability={this.state.purposeHasNoAvailability}
              hidePatientRel={shouldHidePatientRel}
              apptPurposeFilterProps={getApptPurposeFilterProps({
                intl,
                config
              })}
            />
            {providerContent}
            <SearchFooter
              searchSummary={searchSummary}
              showDisclaimer={showDisclaimer}
              totalPages={totalPages}
              getUpdatedSearch={getUpdatedSearch}
              config={config}
              log={log}
            />
          </section>
        </section>
      </main>
    );
  }
}

export default withRouter(injectIntl(Search));
