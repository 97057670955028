import _startCase from 'lodash/startCase';
import _get from 'lodash/get';
import { PMCConfig } from '@kyruus/types';

import {
  SearchFacet,
  SearchFacetWithAppliedTerms,
  SearchSummary
} from 'Src/types';
import messages from './messages';
import { shouldShowFacet } from '../utils';
import { DEFAULT_UNAPPLIED_CHIP_COUNT } from '../constants';

export const getTabIndex = (
  activeTab: number,
  isSelected: boolean,
  tabListIndex: number
): 0 | -1 => {
  const isFirstTab = tabListIndex === 0;
  if (activeTab) {
    // If there is an active tab, and
    // if the current tab is selected, it should be focusable via keyboard.
    // Other tabs will be focusable via the keydown handler on the list.
    return isSelected ? 0 : -1;
  } else {
    // If there is no active tab, and
    // if the current tab is the first tab in the list, it should be focusable via keyboard.
    // Other tabs will be focusable via the keydown handler on the list.
    return isFirstTab ? 0 : -1;
  }
};

/**
 * Check if the filter is a boolean field
 */
const isBooleanField = (filter: SearchFacetWithAppliedTerms): boolean => {
  return (
    filter &&
    Array.isArray(filter.appliedTerms) &&
    filter.appliedTerms[0].value === 'true'
  );
};

/**
 * Get the message for the filter chip
 */
export const getChipMessage = (filter: SearchFacetWithAppliedTerms) => {
  const applied = filter.appliedTerms.length > 0;

  if (applied && isBooleanField(filter) === false) {
    return {
      ...messages.filterChipLabel,
      id: `field.value.${filter.field}.${filter.appliedTerms[0].value}`,
      label: `${filter.appliedTerms[0].value}`
    };
  }

  return {
    ...messages.booleanFilterChipLabel,
    id: `field.name.${filter.field}`,
    label: _startCase(filter.field)
  };
};

/**
 * Get ordered facets with applied and unapplied terms
 * @param config
 * @param facets
 * @param enableLocationFacet
 * @returns { appliedCount: number; orderedFacets: SearchFacetWithAppliedTerms[] }
 */
export const getOrderedFacets = (
  config: PMCConfig,
  facets: SearchFacet[],
  enableLocationFacet: boolean,
  searchSummary: SearchSummary
): {
  appliedCount: number;
  orderedFacets: SearchFacetWithAppliedTerms[];
} => {
  const unappliedFacetLimit = _get(
    config,
    'search_results_page_v9.filterbar.chip.max_visible',
    DEFAULT_UNAPPLIED_CHIP_COUNT
  );
  // map facets to a key value pair for faster lookup
  const facetMap = facets.reduce<{
    [key: string]: SearchFacetWithAppliedTerms;
  }>((acc, facet) => {
    acc[facet.field] = {
      ...facet,
      appliedTerms: facet.terms?.filter((term) => term.applied) || []
    };
    return acc;
  }, {});

  const locationFacet = enableLocationFacet && facetMap['location'];

  const { applied, unapplied } = Object.values(facetMap)
    .filter((f) => shouldShowFacet(f))
    .reduce<{
      applied: SearchFacetWithAppliedTerms[];
      unapplied: SearchFacetWithAppliedTerms[];
    }>(
      (acc, facet) => {
        if (facet.appliedTerms.length > 0) {
          acc.applied.push(facet);
        } else {
          acc.unapplied.push({
            ...facet,
            appliedTerms: facet.terms.filter((term) => term.applied)
          });
        }
        return acc;
      },
      { applied: [], unapplied: [] }
    );

  const sortedApplied = searchSummary.filter
    ? applied.sort((a, b) => {
        const filterAIdx = (searchSummary.filter || []).findIndex((f) =>
          f.includes(a.field)
        );
        const filterBIdx = (searchSummary.filter || []).findIndex((f) =>
          f.includes(b.field)
        );

        if (filterAIdx > filterBIdx) {
          return 1;
        }

        if (filterBIdx > filterAIdx) {
          return -1;
        }

        return 0;
      })
    : applied;

  const limitedUnappliedFacets = unapplied.slice(0, unappliedFacetLimit);
  const orderedFacets = [...sortedApplied, ...limitedUnappliedFacets];

  if (locationFacet) {
    orderedFacets.unshift(locationFacet);
  }
  return {
    appliedCount: applied.length,
    orderedFacets
  };
};
